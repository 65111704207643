<template>
	<div class="wrapper ion-padding" :style="`background: ${card.category.color};`">
		<ion-row  class="back" @click="closeModal">
			<ion-icon :icon="arrowBackOutline"></ion-icon>
			<span class="text-bold">{{ $t("Back") }}</span>
		</ion-row>
		<div class="image-placeholder position-relative" :style="`border: 2px black ${post.photo ? 'none' : 'dotted'}`" @click="addPhoto">
			<div class="image position-relative" v-if="post.photo">
				<img :src="post.photo" >
				<ion-icon class="position-absolute" :icon="trashBinOutline" @click="clearPhoto"></ion-icon>
			</div>
			<ion-icon v-else class="add-icon position-absolute" :icon="addOutline"></ion-icon>
		</div>
		<ion-item class="caption position-relative" lines="none" :style="`border: 2.75px solid ${post.text.length < 161 ? 'black' : 'red'}`">
			<ion-textarea class="text-bold" rows="10" maxlength="160" :placeholder="$t('Tap to write a caption')" v-model="post.text"></ion-textarea>
			<span class="position-absolute text-bold">{{post.text.length}}/160</span>
		</ion-item>
		<ion-button @click="sharePost" :disabled="!post.text.length || !(post.text.length < 161)">{{ $t("Share") }}</ion-button>
	</div>
</template>

<script>
import { modalController } from '@ionic/vue'
import { addOutline, trashBinOutline, arrowBackOutline } from 'ionicons/icons'
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core"
import axios from 'axios'
import {mapState} from "vuex"

export default {
	props:{
		card: {
			type: Object,
			required: true
		},
		photo:{
			type: String,
			required: false,
			default: ''
		}
	},
	setup(){
		return{
			addOutline,
			trashBinOutline,
			arrowBackOutline
		}
	},
	mounted(){
		this.post.photo = this.photo
	},
	data(){
		return{
			post:{
				photo: null,
				text: ''
			}
		}
	},
  computed: {
    ...mapState('offline', ['activeCampaign']),
  },
	methods:{
		async addPhoto(){
			if(this.post.photo) return

			const {Camera} = Plugins
			try {
				const photo = await Camera.getPhoto({
					quality: 100,
					resultType: CameraResultType.Uri,
					source: CameraSource.Prompt
				})

				this.post.photo = photo.webPath

			} catch (error) {
				console.error(error)
			}
		},
		clearPhoto(e){
			e.stopPropagation()
			this.post.photo = null
		},
		closeModal(){
			modalController.dismiss({postShared: false})
		},
		async sharePost(){
		  let postData = new FormData()
			let photoBlob = {}
			if(this.post.photo){
				photoBlob = await fetch(this.post.photo).then(r => r.blob())
				postData.append('image', photoBlob)
			}
			postData.append('caption', this.post.text)
			postData.append('campaign_id', this.activeCampaign)
			postData.append('card_id', this.card.id)
			postData.append('is_public', 1)
			postData.append('title', this.post.text)

			await axios.post('/feed', postData)

			modalController.dismiss({postShared: true})
		}
	}

}
</script>

<style scoped lang="scss">

.wrapper {
    min-height: 100vh;
	overflow: scroll;
}

.image-placeholder {
    min-height: 55vw;
    border-radius: 3.2vw;
	margin-top: 6vw;

	img {
		width: 100%;
		border-radius: 3.2vw;
		border: 0.5vw solid black;
	}
}

.add-icon {
    font-size: 16vw;
    width: 100%;
    top: calc(50% - 8vw);
}

.image {
	ion-icon {
		font-size: 8vw;
		bottom: 14px;
		right: 24px;
	}
}

.back {
	span {
		display: inline;
		line-height: 7.5vw;
		font-size: 4.5vw;
	}

	ion-icon {
    	font-size: 7.5vw;
	}
}

.caption {
	max-height: 35vw;
    border: 0.7vw solid black;
    border-radius: 3.2vw;
    margin-top: 10vw;

	ion-textarea {
		max-height: 35vw;
		border-radius: 3.2vw;
		font-size: 3.2vw;
	}

	span {
    	bottom: 2.8vh;
    	right: 20px;
		font-size: 3.2vw;
	}
}

ion-button {
    height: 17vw;
    margin-top: 10vw;
	margin-bottom: 20vw;
    width: 100%;
	font-size: 5vw;
	--background: black;
	--border-radius: 3.2vw;
}

</style>
