<template>
	<ion-page>
		<ion-content class="ion-padding" :style="`--ion-background-color: ${cardColor || '#fdd835'};`">
			<div class="wrapper notch-margin-top" :style="`background-color: ${cardColor || '#fdd835'};`">
				<ion-row class="a-header">
					<ion-col size="5">
						<div class="a-logo font-monoxil-bold text-bold">
							BEEN<br>THERE<br>TOGETHER
						</div>
					</ion-col>
					<ion-col size="2">
						<div class="mt-5 ion-text-center">
							<ion-icon v-if="!cycledAllCategories" :icon="addCircleOutline" @click="openShareModal"></ion-icon>
						</div>
					</ion-col>
					<ion-col size="5">
						<div class="mt-5 ion-text-right">
							<ion-icon :icon="closeOutline" @click="$router.push({name: 'Categories'})"></ion-icon>
						</div>
					</ion-col>
				</ion-row>

				<div v-if="!cycledAllCategories">
					<div class="wrapperone" v-for="(card,i) in playingCards" :key="card.id"  v-show="i == activeCardIndex">
						<z-card class="mt5" :card="card" :countString="`${i+1}/${playingCards.length}`"/>
					</div>
					<ion-row class="footer ion-text-center">
						<ion-col size="4">
							<ion-icon :icon="playForwardCircle" @click="skipCard"></ion-icon>
						</ion-col>
						<ion-col size="4">
							<ion-icon :icon="informationCircleOutline" :style="`opacity: ${hasStoryBehind?1:0.4};`" @click="openStoryBehind"></ion-icon>
						</ion-col>
						<ion-col size="4">
							<ion-icon :icon="checkmarkCircle" @click="nextStep"></ion-icon>
						</ion-col>
					</ion-row>
				</div>
				<div v-else class="cards-finished-wrapper ion-text-center text-bold font-monoxil-bold">
					<h1 class="font-monoxil-bold">{{ $t("Deck finished") }}</h1>
					<div class="middle-buttons">
						<ion-button class="text-black" @click="playSkippedCards" :disabled="!gameSkippedCards.length">{{ $t("Play skipped cards") }}</ion-button>
						<ion-button class="text-black" @click="$router.push({name: 'Categories'})">{{ $t("Shuffle new deck") }}</ion-button>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import { mapState } from 'vuex'
import { closeOutline, addCircleOutline, playForwardCircle, informationCircleOutline, checkmarkCircle } from 'ionicons/icons'
import { modalController } from '@ionic/vue'

import { createGesture } from '@ionic/core'

import Card from '@/plugins/app/_components/z-card.vue'
import StoryBehind from '@/plugins/app/_components/z-story-behind.vue'
import Share from '@/plugins/app/cards/a-share.vue'


export default {
	name: 'cards-ui',
	components:{ 'z-card': Card },
	setup(){
		return{
			closeOutline,
			addCircleOutline,
			playForwardCircle,
			informationCircleOutline,
			checkmarkCircle,

		}
	},
	data(){
		return{
			activeCardIndex: 0,
			playingSkipped: false,
			cardShared: false,
			windowWidth: window.innerWidth,
			gesture: null,
			lastColor: ''


		}
	},
	async mounted(){
    if (!this.activeCampaign)
      this.$router.push({name: 'Campaigns'})

    await this.$store.dispatch('game/shuffleCardsFromCategory', this.$route.params.categoryId)
		const allCards = document.getElementsByClassName('mt5')
		const wrapperone = document.getElementsByClassName('wrapperone')
		//nastudovat callback functions
		//notes: pri hrani skipped cards sa ziadna funkcionalita neprenesie

		allCards.forEach((el) => {
			const hammertime = new Hammer(el)
			hammertime.get('swipe').set({ direction: Hammer.DIRECTION_ALL})
			// hammertime.on('swiperight', () => this.nextStep())
			// hammertime.on('swipeleft', () => this.skipCard())
			// hammertime.on('swipeup', () => this.openShareModal())
			// hammertime.on('swipedown', () => this.openStoryBehind())
		})

		const draggies = []
		for ( let i=0; i < wrapperone.length; i++ ) {
			let draggableElem = wrapperone[i] //skusal som pouzit refs ale plugin sa da inicializovat len vo vanille
			const  draggie = new Draggabilly( draggableElem, {
				axis: 'x'
			})
			// debugger
			let style = draggableElem.children[0].style

			let windowWidth = window.innerWidth
			let center = windowWidth/2
			let start = 0



			draggie.on('dragStart', (ev) => {
				console.log('move starting!')
				console.log(ev)
				console.log(windowWidth)
				style.transition = 'none'
				start = ev.x


			})
			draggie.on('dragMove', (ev, moveVector) => {
				console.log(moveVector)
				style.transform = `rotate(${((ev.x-(1/2*windowWidth)-(start-(1/2*windowWidth)))/center*10)}deg)`
			})
			draggie.on('dragEnd', (ev) => {

				style.transition = "0.5s ease-out"

				if(ev.clientX > center+(0.25*center)){
					console.log('you swiped right!')
					style.transform = `translateX(${windowWidth * 1.5}px)`
					setTimeout(()=>{
						this.nextStep()
					}, 400)

				} else if (ev.clientX < center-(0.25*center)){
					style.transform = `translateX(-${windowWidth * 1.5}px)`
					console.log('you swiped left')
					setTimeout(()=>{
						this.skipCard()
					}, 400)
				} else {
					draggableElem.style.transform = ''
					draggableElem.style.left = ''
					style.transform = ''
				}

				// console.log(ev)
			})
			draggies.push(draggie)
		}




		// setTimeout(() => {
		// 	this._initGesture() comment: zatial nechavam minimalne pre future reference
		// }, 4000)


	},

	// created(){
	// 	var VueDraggabilly = require('vue-draggabilly')
	// 	Vue.use(VueDraggabilly)
	// },

	// setup(){


	// 	// let logo = ref(null)
	// 	// const gesture = createGesture({
	// 	// 	el: $refs.logo.el,
	// 	// 	onMove: (e) => console.log(e)
	// 	// })
	// 	// gesture.enable()

	// },
	methods:{
		nextStep(){
			this.activeCardIndex++

			if(this.playingSkipped) return

			if(!this.cycledAllCategories && this.activeCardIndex+1 > this.gameCards.length){
				this.$store.commit('game/categoryFinished', this.$route.params.categoryId)

				if(!this.cycledAllCategories) this.$router.push({name: 'Categories'})
			}
		},
		skipCard(){
			this.$store.commit('game/cardSkipped', this.playingCards[this.activeCardIndex])
			this.nextStep()
		},
		async openShareModal(){
			const modal = await modalController
				.create({
					component: Share,
					componentProps: {
						card: this.playingCards[this.activeCardIndex]
					},
				})
			modal.present()
			const {data} = await modal.onDidDismiss()
			if(data?.postShared) this.cardShared = true
		},
		async openStoryBehind(){
			// console.log(this.hasStoryBehind)
			if(!this.hasStoryBehind) return
			const modal = await modalController
				.create({
					component: StoryBehind,
					componentProps: {
						card: this.playingCards[this.activeCardIndex],
					},
				})
			return modal.present()

		},

		playSkippedCards(){
			//save skipped cards into component state and reset store cards for recursive behaviour
			this.playingSkipped = false //triggers computed if action is repeated
			this.skippedCardsDeck = this.gameSkippedCards
			this.activeCardIndex = 0
			this.playingSkipped = true

			this.$store.commit('game/skippedCardsPlayed')
		},
		_initGesture(){
			console.log(this.$refs.deck.$el)
			this.gesture = createGesture({
				el: this.$refs.deck.$el,
				gestureName: 'card-swipe',

				onStart: () => {
					//will do something once the gesture begins
					console.log("starting")
					this.$refs.deck.style.transition = "none"
				},
				onMove: (ev) => { //ev nam podava data user akcii (napr. kolko user pohol s komponentom)
					//do something after the movement has been registered
					console.log(ev)
					this.$refs.deck.style.transform = `translateX(${ev.deltaX}px) rotate(${ev.deltaX/30}deg)`
				},
				onEnd: (ev) => {
					//console.log('ending')
					//console.log(ev)
					//do something when the gesture ends
					style.transition = "0.3s ease-out"
					if(ev.deltaX > windowWidth/2){
						this.$refs.deck.style.transform = `translateX(${windowWidth * 2}px)` //windowwidth se to 1.5 before
						nextStep()
					} else if (ev.deltaX < -windowWidth/2){
						this.$refs.deck.style.transform = `translateX(-${windowWidth * 2}px)`
						skipCard()
					} else {
						this.$refs.deck.style.transform = ' '
					}
				}
			})

			this.gesture.enable(true)
		},
	},
	computed: {
		...mapState('game', {gameCards: 'shuffledCards', gameSkippedCards: 'skippedCards', gameFinishedCategories: 'finishedCategories'}),
		...mapState('offline', {offlineCategories: 'categories'}),
    ...mapState('offline', ['activeCampaign']),

		playingCards(){
			return this.playingSkipped ? this.skippedCardsDeck : this.gameCards
		},
		categoryColor(){
			return this.offlineCategories.filter(category => category.id == this.$route.params.categoryId)[0]?.color
		},
		cardColor(){
			return this.playingCards[this.activeCardIndex]?.category?.color
			// this.lastColor =
		},
		cycledAllCategories(){
			return (this.playingCards.length == this.activeCardIndex) && !(this.offlineCategories.filter(category => !this.gameFinishedCategories.includes(`${category.id}`)).length)
		},
		hasStoryBehind(){
			// console.log(this.playingCards[this.activeCardIndex]?.story.length)
			return !!this.playingCards[this.activeCardIndex]?.story?.length ?? false
		}
	},

}


</script>

<style lang="scss" scoped>

.wrapper {
	width: 100%;
	min-height: 80vh;
}

ion-icon {
	font-size: 10vw;
}

.footer {
	margin-top: 8vw;
}

.wrapperone {
	margin-top: 10vw;
	// transform-origin: center;
}
.cards-finished-wrapper {

	ion-button {
		height: 17vw;
		width: 91.5vw;
		border: 0.733vw solid black;
		border-radius: 4.26vw;
		font-size: 4.8vw;
		margin-top: 6.4vw;
		--background: none;
		--background-activated: rgba(172, 172, 172, 0.453);
	}

	h1 {
		font-size: 8.5vw;
		margin-top: 13.9vw;
	}

	.middle-buttons{
		margin-top: 6.4vw;
	}
}

.bottom-btn {
	--background: black !important;
	--background-activated: rgb(36, 33, 33);
}

.disabledIcon {
	opacity: 0.4;
}
</style>
