<template>
	<main id="app" class="ion-padding">
		<ion-row>
			<nav :style="{ gridTemplateColumns: `repeat(${stories.length}, 1fr)` }">
				<div v-for="(story, index) in stories" :key="index">
					<div :style="`background: ${card.category.color};`"></div>
				</div>
			</nav>
			<svg width="24" height="24" viewBox="0 0 24 24" :style="`stroke: ${card.category.color};`" xmlns="http://www.w3.org/2000/svg" @click="closeStory">
				<path d="M18.75 5.25L5.25 18.75" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M18.75 18.75L5.25 5.25" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</ion-row>
		<ion-slides ref="storySlides" id="storySlides" :options="slideOpts">
			<ion-slide class="no-swipe" v-for="(story, index) in stories" :key="index"> <!-- pozriet v-if mixing w/ v for-->
				<ion-card class="text-white">
					<img v-if="card.story[index].story_picture" :src="storyPicture(index)">
					<ion-card-content class="ion-no-padding ion-padding-vertical ion-text-left">
						<span class="ion-padding-bottom" @click="getIndex">{{card.story[index].story_text}}</span>
					</ion-card-content>
				</ion-card>
			</ion-slide>
		</ion-slides>
	</main>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'
import { modalController } from '@ionic/vue'

export default {
	el: '#app',
	data() {
		return {
			stories: this.card.story,
			current: 0,
			storySlides: document.querySelector('#storySlides')
		}
	},
	setup(){
		const slideOpts = {
			centeredSlides: true,
			spaceBetween: 10,
			initialSlide: 0,
			noSwiping: true,
			noSwipingClass: 'no-swipe'

		}
		return{ slideOpts }
	},
	methods:{
		closeStory(){
			modalController.dismiss()
		},
		// async test(event){
		// 	this.currentIndex = await event.currentTarget.getActiveIndex()
		// },
		storyPicture(index){
			return `https://be-dev.beentheretogether.cards/storage/app/media/${this.card.story[index].story_picture}`
		},
	},
	props:{
		card:{
			type: Object,
			required: true
		},
	},

	mounted() {
	  let running = true;
	  const speed = 1000

		let timeline = anime.timeline({
			autoplay: true,
			duration: speed,
			easing: 'linear',
			loop: true
		})

		const storySlides = document.querySelector('#storySlides')
		storySlides.getSwiper()

		this.stories.forEach((story, index) => {
			timeline.add({
				targets: document.querySelectorAll('nav > div')[index].children[0],
				width: '100%',
				changeBegin: (a) => {
					this.current = index
				},
        update: (a) => {
				  if(!running)
				    return

          if(Math.round(a.progress) == 100 && index == this.current) {
				    console.log(Math.round(a.progress), a)
            if(this.current == this.stories.length - 1){
              a.pause()
              a.remove()
              running = false
              modalController.dismiss()
            } else storySlides.slideNext()
          }

        }
			})
		})

    let hammertime = new Hammer(document.querySelector('#app'))

		hammertime.on('press', (e) => {
			timeline.pause()
		})

		hammertime.on('pressup', (e) => {
			timeline.play()
		})

		hammertime.on('tap', (e) => {
      if (e.center.x > window.innerWidth / 2) {
        if (this.current == this.stories.length - 1) {
          this.current = 0
          storySlides.slideTo(0)
        } else {
					this.current += 1
					storySlides.slideNext()
				}
			} else {
				if (this.current >= 0) {
					this.current -= 1
					storySlides.slidePrev()
				}
			}

      timeline.pause()
      timeline.seek(this.current * speed)
      timeline.play()
    })
	}
}
</script>

<style scoped lang="scss">
html, body, main, section {
	height: 100%;
	width: 100%;
}

nav {
	box-sizing: border-box;
	display: grid;
	grid-column-gap: 3vw;
	grid-template-columns: repeat(3, 1fr);
	height: 1.5vw;
	margin-top: 2vw;
	margin-right: 5%;
	margin-bottom: 8vw;
	width: calc(95% - 6vw);
	border-radius: 3.2vw;
}

nav > div {
	height: 100%;
	border-radius: 25px;
	background: rgb(110, 110, 110);
}

nav > div > div {
	height: 100%;
	width: 0%;
	border-radius: 3.2vw;
}

svg {
	height: 6vw;
	width: 6vw;
}

main {
	background: black;
	min-height: 100vh;
	overflow: scroll;
}

ion-card {
	background: black;
	margin: 0;
	&-content {
		font-size: 6.5vw;
	}

	img {
		border-radius: 3.2vw;
	}
}

</style>
