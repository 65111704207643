<template>
	<div class="wrapper ion-padding" :style="`background: ${card.category.color};`">
		<div class="caption notch-margin-top">
			<ion-row class="a-header">
				<ion-col size="6">
					<div class="text-bold add-to-feed">{{ $t("Add to feed") }}</div>
				</ion-col>
				<ion-col size="6">
					<ion-icon :icon="closeOutline" @click="closeModal"></ion-icon>
				</ion-col>
			</ion-row>
			<div class="text-medium description">{{ $t("Add to feed perex") }}</div>
		</div>
		<div class="buttons">
			<ion-button class="button-hover-gray text-bold" :style="`color: ${card.category.color}`"
                  v-if="canOpenCamera" @click="addPhoto">{{ $t("Open camera") }}</ion-button>

			<ion-button class="button-hover-gray text-bold" :style="`color: ${card.category.color}`" @click="openPostShareModal">{{ $t("Write a text post") }}</ion-button>
			<ion-button class="back-button font-monoxil text-bold text-black" @click="closeModal">{{ $t("Back to the card") }}</ion-button>
		</div>
	</div>
</template>

<script>
import { closeOutline } from 'ionicons/icons'

import { modalController } from '@ionic/vue'
import {
	Plugins,
	CameraSource,
	CameraResultType
} from "@capacitor/core"

const { Device } = Plugins
import PostShare from '@/plugins/app/cards/a-post-share.vue'


export default {
	props:{
		card: {
			type: Object,
			required: true
		}
	},
	setup(){
		return{
			closeOutline,
		}
	},
	data(){
		return{
			photoWebPath: '',
      canOpenCamera: false
		}
	},
  async mounted() {
    const info = await Device.getInfo();
    console.log(info.platform, info.model)
    if((info.platform === 'ios' && info.model === 'iPhone')
        || info.platform === 'android'
        || info.platform === 'mac'
        || info.platform === 'windows'
    ) {
      this.canOpenCamera = true
    }
  },
	methods:{
		closeModal(){
			modalController.dismiss()
		},
		openCamera(){

		},
		async addPhoto(){
			const {Camera} = Plugins
			try {
				const photo = await Camera.getPhoto({
					quality: 100,
					resultType: CameraResultType.Uri,
					source: CameraSource.Prompt
				})

				this.photoWebPath = photo.webPath
				this.openPostShareModal()

			} catch (error) {
				console.error(error)
			}
		},
		async openPostShareModal(){
			const modal = await modalController
				.create({
					component: PostShare,
					componentProps: {
						card: this.card,
						photo: this.photoWebPath
					},
				})
			modal.present()
			const {data} = await modal.onDidDismiss()
			if(data.postShared) modalController.dismiss({postShared: true})

		},
	}

}
</script>

<style scoped lang="scss">

.wrapper {
    height: 100%;
	font-size: 6.4vw;

	.add-to-feed {
		margin-bottom: 15vw;
		margin-top: 5vw;
		line-height: 10vw;
	}

	.description {
		margin-bottom: 20vw;
	}

}

ion-icon {
	position: absolute;
	right: 0;
	margin-top: 5vw;
	font-size: 10vw;
}

ion-button {
    height: 17vw;
	margin-bottom: 4.2vw;
	font-size: 5.33vw;
	--border-radius: 4vw;
	--background: black;
}

.buttons {
    display: flex;
    flex-direction: column;
}

.back-button {
    border: 0.73vw solid black;
	border-radius: 4vw;
	--background: none;
	--background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
}

</style>
